<template>
  <div>
    <PageHeader />
    <b-tabs content-class="mt-3">
      <b-tab title="CMS" active><CmsSite /></b-tab>
      <b-tab v-if="adminRole === 'OWNER'" title="Advance Settings">
        <CmsAdvanceConfigurationSettingGlobal />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  components: {
    CmsSite: () => import('@components/cms/site/index.vue'),
    CmsAdvanceConfigurationSettingGlobal: () =>
      import('@components/cms/advance-configuration-setting-global.vue'),
  },
  computed: {
    ...mapGetters(['adminRole']),
  },
}
</script>
